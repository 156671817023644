import { useState, useEffect } from "react"

const cachedScripts: Array<string> = []
type Status = [boolean, boolean]

interface IUseScriptOptions {
  src: string | null | undefined
  /** true by default */
  withCache?: boolean
  /** null by default. Unique identifier of the dom element */
  scriptId?: string | null
  /** 0 by default. in miliseconds. Defer script injection */
  delay?: number
}

/**
 *
 * @param src : source of the script
 * @param withCache : flag, which determines if we need to cache script
 * @param scriptId : unique id of the script tag, which is used to remove existing script
 */
export default function useScript(options: IUseScriptOptions): Status {
  const { src, withCache = true, scriptId = null, delay = 0 } = options
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })

  useEffect(() => {
    if (typeof src !== "string") {
      setState({
        loaded: true,
        error: true,
      })
      return undefined
    }

    const cachedScriptIndex = cachedScripts.indexOf(src)

    if (cachedScriptIndex !== -1) {
      if (withCache) {
        // If cachedScripts array already includes src that means another instance ...
        // ... of this hook already loaded this script, so no need to load again.
        setState({
          loaded: true,
          error: false,
        })
        return undefined
      }
      cachedScripts.splice(cachedScriptIndex, 1)
    }

    if (scriptId) {
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        existingScript.remove()
      }
    }

    cachedScripts.push(src)

    // Create script
    const script = document.createElement("script")

    script.src = src
    if (scriptId) {
      script.id = scriptId
    }
    script.async = true

    // Script event listener callbacks for load and error
    const onScriptLoad = () => {
      setState({
        loaded: true,
        error: false,
      })
    }

    const onScriptError = () => {
      // Remove from cachedScripts we can try loading again
      const index = cachedScripts.indexOf(src)
      if (index >= 0) cachedScripts.splice(index, 1)
      script.remove()

      setState({
        loaded: true,
        error: true,
      })
    }

    script.addEventListener("load", onScriptLoad)
    script.addEventListener("error", onScriptError)

    // Add script to document body
    const timeoutId = window.setTimeout(() => {
      document.body.appendChild(script)
    }, delay)

    // Remove event listeners on cleanup
    return () => {
      script.removeEventListener("load", onScriptLoad)
      script.removeEventListener("error", onScriptError)
      window.clearTimeout(timeoutId)
    }
  }, [withCache, scriptId, src, delay])

  return [state.loaded, state.error]
}

export const resetCache = (): void => {
  cachedScripts.length = 0
}
export const getCache = (): Array<string> => cachedScripts
