"use client"

import React, {
  useState,
  UIEvent,
  useEffect,
  useRef,
  MutableRefObject,
} from "react"
import clsx from "clsx"
import {
  IMultiLevelSlugNode,
  IGroupedItems,
  IHeadlinePage,
  ILinkedFromPageItem,
} from "../../types"
import { AdviqoLink, Box, useTheme } from "../_common"
import { buildLinkUrl } from "../../utils/slugUtils"
import { useViewport } from "../../hooks/viewport"
import ChevronIcon from "../_common/QuesticoIcon/ChevronIcon"
import { Tag } from "../Experts/Tags/Tags"
import useAsPath from "../../components/hooks/useAsPath"
import classes from "./SideNavigation.module.scss"

interface ISideNavigationProps {
  navigationItems: IGroupedItems
  childItemClickHandler?: () => void
}

interface IPageNodeEnriched extends ILinkedFromPageItem {
  url: string
  isActive: boolean
}

const renderChildNavItem = (
  pageNode: IPageNodeEnriched,
  activeLinkRef: MutableRefObject<HTMLDivElement[]>,
  childItemClickHandler?: () => void
) => (
  <div
    key={pageNode.sys.id}
    className={clsx(classes.childNavItem, {
      [classes.activeMobileSideNavItem]: pageNode.isActive,
    })}
    ref={(el: HTMLDivElement) => activeLinkRef.current.push(el)}
  >
    <AdviqoLink to={pageNode.url} onClick={childItemClickHandler}>
      {pageNode.title}
    </AdviqoLink>
  </div>
)

const getNavItemTitle = (navigationItem: IMultiLevelSlugNode) => {
  return navigationItem.page && navigationItem.page.title
}

const shouldShowNewTagLabel = (navigationItem: IMultiLevelSlugNode) => {
  return navigationItem.page && navigationItem.page.showNewLabel
}

const SideNavigation: React.FC<ISideNavigationProps> = ({
  navigationItems,
  childItemClickHandler,
}) => {
  const { isViewport } = useViewport()
  const isMobile = isViewport("xs")
  const theme = useTheme()
  const activeLinkRef = useRef<Array<HTMLDivElement>>([])

  const [lighterBefore, setLighterBefore] = useState<boolean>(false)
  const [lighterAfter, setLighterAfter] = useState<boolean>(true)
  const asPath = useAsPath()

  useEffect(() => {
    if (isMobile) {
      for (let i = 0; i < activeLinkRef.current.length; i += 1) {
        if (
          activeLinkRef.current[i] !== null &&
          activeLinkRef.current[i].classList[1] ===
            classes.activeMobileSideNavItem
        ) {
          activeLinkRef.current[i].scrollIntoView({
            behavior: "smooth",
            inline: "center",
          })
          break
        }
      }
    }
  }, [isMobile, classes.activeMobileSideNavItem, asPath])

  if (!navigationItems) {
    return null
  }

  const renderNavItem = (
    navigationItem: IMultiLevelSlugNode,
    hasActiveChildItem: boolean
  ) => {
    const title = getNavItemTitle(navigationItem)
    const hasNewTag = shouldShowNewTagLabel(navigationItem)
    const navItemUrl = buildLinkUrl(navigationItem)

    const link = (
      <AdviqoLink to={navItemUrl}>
        {title}
        {hasNewTag && (
          <Tag clickable={false} rootClassName={classes.tag} label="is_new" />
        )}
      </AdviqoLink>
    )
    const canBeActive = !isMobile || (isMobile && !hasActiveChildItem)

    const isActiveSideNavigationItem = Boolean(
      canBeActive && asPath && asPath.startsWith(navItemUrl)
    )

    return (
      <div
        className={clsx(classes.navItem, {
          [classes.activeMobileSideNavItem]: isActiveSideNavigationItem,
        })}
        ref={(el: HTMLDivElement) => activeLinkRef.current.push(el)}
      >
        {!isMobile && isActiveSideNavigationItem && (
          <Box
            component="span"
            display="inline"
            className={classes.rectangle}
          />
        )}
        {link}
        {!isMobile && (
          <Box component="span" display="inline" className={classes.arrow}>
            <ChevronIcon
              height={19}
              width={11}
              color={theme.palette.grey[300]}
            />
          </Box>
        )}
      </div>
    )
  }

  const renderNavigationItem = (navigationItem: IMultiLevelSlugNode) => {
    const childrenNavigationItems =
      (navigationItem.linkedFrom &&
        navigationItem.linkedFrom.pageCollection.items) ||
      []

    let hasActiveChildItem = false

    const children = childrenNavigationItems.map((item) => {
      const url = buildLinkUrl(item)
      const isActive = Boolean(asPath && asPath.startsWith(url))
      hasActiveChildItem = isActive || hasActiveChildItem

      return renderChildNavItem(
        {
          ...item,
          url,
          isActive,
        },
        activeLinkRef,
        childItemClickHandler
      )
    })

    return (
      <React.Fragment key={navigationItem.sys.id}>
        {renderNavItem(navigationItem, hasActiveChildItem)}
        {isMobile && children}
      </React.Fragment>
    )
  }

  const renderCategory = (
    category: string,
    index: number,
    headlinePage: IHeadlinePage | null
  ) => {
    const categoryClasses = clsx(classes.category, {
      [classes.rounded]: index === 0,
    })

    const categoryBox = <Box className={categoryClasses}>{category}</Box>

    return headlinePage ? (
      <AdviqoLink to={buildLinkUrl(headlinePage)}>{categoryBox}</AdviqoLink>
    ) : (
      categoryBox
    )
  }

  const scrollHandler = (event: UIEvent<HTMLDivElement>) => {
    if (!isMobile) {
      return
    }

    const lighterBefore = event.currentTarget.scrollLeft > 0
    setLighterBefore(lighterBefore)

    const lighterAfter =
      event.currentTarget.scrollLeft <
      event.currentTarget.scrollWidth - event.currentTarget.clientWidth

    setLighterAfter(lighterAfter)
  }

  const scrollerWrapperClsx = clsx(classes.scrollerWrapper, {
    [classes.withLighterBefore]: isMobile && lighterBefore,
    [classes.withLighterAfter]: isMobile && lighterAfter,
  })

  return (
    <Box className={classes.container}>
      <Box className={scrollerWrapperClsx}>
        <Box className={classes.scroller} onScroll={scrollHandler}>
          {Object.keys(navigationItems).map(
            (category: string, index: number) => (
              <React.Fragment key={category}>
                {!isMobile &&
                  category !== "ALL" &&
                  renderCategory(
                    category,
                    index,
                    navigationItems[category].headlinePage
                  )}
                {navigationItems[category].navItems.map(renderNavigationItem)}
              </React.Fragment>
            )
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SideNavigation
