"use client"
import React from "react"
import clsx from "clsx"

import {
  Box,
  Grid,
  Typography,
  AdviqoLink,
  useMediaQuery,
  useTheme,
  NextImage,
} from "../../_common"
import Richtext from "../../RichText"
import { buildLinkUrl } from "../../../utils/slugUtils"
import { ContentItemListProps, ContentItemProps } from "../types"
import { makeAttributesObject } from "../utils"
import useAsPath from "../../../components/hooks/useAsPath"
import classes from "./SmallGrid.module.scss"
import commonStyles from "@styles/common.module.scss"

const ContentItem: React.FC<ContentItemProps> = ({
  headline,
  image: imageMobile,
  imageDesktop,
  textAlign,
  link,
}) => {
  const theme = useTheme()
  const path = useAsPath()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const image = !isMobile && imageDesktop ? imageDesktop : imageMobile
  const url = link && buildLinkUrl(link)

  const itemClasses = clsx(classes.item, {
    [classes.active]: path === url,
  })

  const itemHeadlineClasses = clsx(
    classes.itemHeadline,
    commonStyles[`text-align--${textAlign}`]
  )

  const content = (
    <Box className={itemClasses}>
      <div className={classes.itemImageWrapper}>
        <NextImage
          className={classes.image}
          src={image.url}
          width={image.width}
          height={image.height}
          alt={headline}
        />
      </div>
      {headline && (
        <Typography variant="h4" className={itemHeadlineClasses}>
          {headline}
        </Typography>
      )}
    </Box>
  )

  const item = url ? (
    <AdviqoLink
      to={url}
      onClick={(e) => {
        const { href } = e.currentTarget as HTMLLinkElement
        const [, anchor] = href.split("#")
        const target = document.querySelector(`#${anchor}`)

        if (target) {
          e.preventDefault()
          target.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        }
      }}
    >
      {content}
    </AdviqoLink>
  ) : (
    content
  )

  return (
    <Grid item xs={6} sm={3} md={2} component="li" className={classes.root}>
      {item}
    </Grid>
  )
}

const SmallGrid: React.FC<ContentItemListProps> = (props) => {
  const {
    listHeadline,
    listSubHeadline,
    itemsCollection: { items },
    dataAttributes,
  } = props
  const contentItems = items.map((item) => (
    <ContentItem key={item.sys && item.sys.id} {...item} />
  ))

  return (
    <div {...makeAttributesObject(dataAttributes)}>
      {listHeadline && (
        <Typography variant="h2" className={classes.listHeadline}>
          {listHeadline}
        </Typography>
      )}
      {listSubHeadline && (
        <div className={classes.listSubHeadline}>
          <Richtext content={listSubHeadline} />
        </div>
      )}
      <Grid container component="ul" spacing={2} className={classes.list}>
        {contentItems}
      </Grid>
    </div>
  )
}

export default SmallGrid
