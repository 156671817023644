"use client"

import { usePathname, useSearchParams } from "next/navigation"

const useAsPath = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  return `${pathname}?${searchParams}`
}

export default useAsPath
