import React from "react"
import clsx from "clsx"

import { NextImage } from "../../../_common"
import { ContentItemProps } from "../../types"
import { makeAttributesObject } from "../../utils"
import { TextAlign } from "../../../../types"
import classes from "./Collage.module.scss"
import commonStyles from "@styles/common.module.scss"

const Collage: React.FC<ContentItemProps> = ({
  headline,
  textAlign = TextAlign.LEFT,
  image,
  classNames,
  dataAttributes,
}) => {
  const imageSrc = image ? image.url : ""
  const headlineClasses = clsx(
    classes.headline,
    commonStyles[`text-align--${textAlign}`]
  )
  return (
    <div
      className={clsx(classes.root, classNames)}
      {...makeAttributesObject(dataAttributes)}
    >
      <NextImage
        src={imageSrc}
        height={550}
        width={352}
        className={classes.image}
        alt={headline}
      />
      <div className={classes.headlineWrapper}>
        <span className={headlineClasses}>{headline}</span>
      </div>
    </div>
  )
}

export default Collage
