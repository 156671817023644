"use client"

import { usePathname } from "next/navigation"
import React from "react"
import clsx from "clsx"
import { Box, AdviqoLink } from "../../_common"
import { LinkProps } from "../../../types"
import { NavigationList } from "../../NavigationList"
import classes from "./ChipsNavigation.module.scss"

interface IChipsNavigationProps {
  menuItem: LinkProps | undefined
}

const ChipsNavigation: React.FC<IChipsNavigationProps> = ({ menuItem }) => {
  const path = usePathname()

  return (
    <>
      {menuItem?.title && (
        <Box className={classes.listTitle}>{menuItem.title}</Box>
      )}

      <Box className={classes.root}>
        {menuItem?.title && menuItem.url && (
          <AdviqoLink
            className={clsx(classes.chip, {
              [classes.active]: path === menuItem.url,
            })}
            to={menuItem.url}
            data-testid={menuItem.url.replace("/", "")}
          >
            {menuItem.title}
          </AdviqoLink>
        )}

        {menuItem?.links && menuItem.links.length > 0 && (
          <NavigationList
            items={menuItem.links}
            path={path}
            listTitleClassName={classes.listTitle}
            listClassName={classes.list}
            tagClassName={classes.tag}
            listItemActiveClassName={classes.active}
          />
        )}
      </Box>
    </>
  )
}

export default ChipsNavigation
