"use client"

import { usePathname } from "next/navigation"
import React from "react"

import SideNavigation from "../../ui-lib/NewNavigation/SideNavigation"
import ChipsNavigation from "../../ui-lib/NewNavigation/SideNavigation/ChipsNavigation"
import { LinkProps } from "../../types"
import { getActiveLevelItem, getActiveTopLevelItem } from "../utils/navigation"
import classes from "./NewSideNavigation.module.scss"

interface INavigationComponentProps {
  items: LinkProps[]
}

const NewSideNavigation: React.FC<INavigationComponentProps> = ({ items }) => {
  const pathname = usePathname()

  const activeTopLevelItem = getActiveTopLevelItem(items, pathname)
  const activeLevelItem = getActiveLevelItem(items, pathname)

  return (
    <>
      <nav className={classes.chipsNavigation}>
        <ChipsNavigation menuItem={activeLevelItem} />
      </nav>

      <nav className={classes.sideNavigation}>
        <SideNavigation menuItem={activeTopLevelItem} isSticky={false} />
      </nav>
    </>
  )
}

export default NewSideNavigation
