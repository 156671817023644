"use client"

import { usePathname, useSearchParams } from "next/navigation"
import { useTracking } from "../../hooks/tracking"
import { useEffect } from "react"
import { buildPageEventNameFromPathname } from "../../utils/utils"

interface IPageEventTrackingProps {
  page?: string
  /**
   * we assume SEO tracks page load event every first page visit
   * and SEO can be used as a part of some Layout or on its own.
   * With this flag we can control whether page tracking event should happen or not
   * for particular SEO component instance.
   */
  trackPageView: boolean
}

const PageEventTracking = ({
  page,
  trackPageView,
}: IPageEventTrackingProps) => {
  const { trackEvent, clientTrackingIsReady } = useTracking()

  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    const eventName = page || buildPageEventNameFromPathname(pathname)

    const isRawSearchPage =
      searchParams.has("slug") &&
      searchParams.get("slug") === "search" &&
      searchParams.has("search") &&
      searchParams.size === 2 // prevent track search page with only one param because will attach additional params next tick

    if (
      trackPageView &&
      clientTrackingIsReady &&
      eventName !== undefined &&
      !isRawSearchPage
    ) {
      trackEvent({
        method: "page",
        eventName,
        title: document.title,
      })
    }
  }, [
    clientTrackingIsReady,
    page,
    pathname,
    searchParams,
    trackEvent,
    trackPageView,
  ])

  return null
}

export default PageEventTracking
