"use client"

import React from "react"
import { useTranslations } from "next-intl"
import { Snackbar } from "../../_common"
import { useProductService } from "../../../hooks/productService"
import { CallStatusType } from "../../../types"
import classes from "./ActiveProductSnackbar.module.scss"

const ActiveProductSnackbar: React.FC = () => {
  const translate = useTranslations()

  const {
    productIsActive,
    goToStep,
    avatar,
    productType,
    activeCallSessionData,
  } = useProductService()

  if (!productIsActive) {
    return null
  }

  let message

  switch (productType) {
    case "call": {
      message =
        activeCallSessionData?.details.status === CallStatusType.CREATED
          ? translate("productFlow.snackBar.callCreated", {
              brand: translate("brandName"),
            })
          : translate("productFlow.snackBar.callInProgress")
      break
    }

    case "chat": {
      message = translate("productFlow.snackBar.chatInProgress")
      break
    }

    default: {
      message = ""
      break
    }
  }

  return (
    <Snackbar
      isOpen={true}
      closingComponent={undefined}
      onClick={goToStep}
      contentClassName={classes.content}
    >
      {avatar && <img className={classes.photo} src={avatar} alt={message} />}

      {message}
    </Snackbar>
  )
}

export default ActiveProductSnackbar
