"use client"

import React, { useRef } from "react"
import clsx from "clsx"
import Slider from "react-slick"

import { Typography } from "../../_common"
import { ItemViewOptions } from "../../../types"
import Richtext from "../../RichText"
import ContentItem from "../ContentItem/ContentItem"
import { ContentItemListProps } from "../types"
import ArrowIcon from "../../_common/QuesticoIcon/ArrowIcon"
import { makeAttributesObject } from "../utils"
import classes from "./Carousel.module.scss"

interface IButton {
  classNames: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  sliderRef: React.RefObject<Slider>
  children: React.ReactNode
}

const Button: React.FC<IButton> = ({
  classNames,
  onClick,
  children,
  sliderRef,
}) => {
  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) onClick(e)
    if (sliderRef && sliderRef.current) sliderRef.current.slickPause()
  }

  return (
    <button type="button" onClick={clickHandler} className={classNames}>
      {children}
    </button>
  )
}

const Carousel: React.FC<ContentItemListProps> = (props) => {
  const {
    listHeadline,
    listSubHeadline,
    itemsCollection: { items },
    itemViewOptions,
    fullScreen,
    dataAttributes,
  } = props
  const sliderRef = useRef<Slider>(null)

  const settings = {
    arrows: true,
    autoplay: true,
    dots: true,
    easing: "ease-in",
    centerMode: true,
    centerPadding: fullScreen ? "60px" : "0",
    infinite: true,
    pauseOnHover: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 10000,
    nextArrow: (
      <Button
        classNames={clsx(classes.button, classes.nextButton, {
          [classes.nextButtonFullScreen]: fullScreen,
        })}
        sliderRef={sliderRef}
      >
        <ArrowIcon height={20} width={26} />
      </Button>
    ),
    prevArrow: (
      <Button
        classNames={clsx(classes.button, classes.prevButton, {
          [classes.prevButtonFullScreen]: fullScreen,
        })}
        sliderRef={sliderRef}
      >
        <ArrowIcon height={20} width={26} />
      </Button>
    ),
    onSwipe() {
      sliderRef.current?.slickPause()
    },
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false,
          centerMode: false,
          dots: false,
          variableWidth: true,
          slidesToShow: 1,
        },
      },
    ],
  }

  const subHeadlineClassName = clsx(classes.subHeadline, {
    [classes.subHeadlineStack]: itemViewOptions === ItemViewOptions.STACK,
  })

  return (
    <div
      className={clsx(classes.root, {
        [classes.fullScreen]: fullScreen,
      })}
      {...makeAttributesObject(dataAttributes)}
    >
      {listHeadline && (
        <Typography variant="h2" className={classes.listHeadline}>
          {listHeadline}
        </Typography>
      )}
      {listSubHeadline && (
        <div className={subHeadlineClassName}>
          <Richtext content={listSubHeadline} />
        </div>
      )}
      <div className={classes.carousel}>
        {/* @ts-ignore */}
        <Slider {...settings} ref={sliderRef}>
          {items.map((item, index) => (
            <ContentItem
              {...item}
              key={item.sys && item.sys.id}
              itemViewOptions={itemViewOptions}
              classNames={clsx(classes.item, {
                [classes.extraMargin]: fullScreen && index % 2 === 0,
                [classes.itemFullScreen]: fullScreen,
              })}
            />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Carousel
