import { IReviveAdsPosition, IReviveConfig } from "../../types"
import { IPublicRuntimeConfig } from "../runtimeConfig"

export const isAdsEnabled = (
  isMobile: boolean,
  getRuntimeConfig: () => IPublicRuntimeConfig
): boolean => {
  const {
    EXPERT_ADS_BANNER_DESKTOP_VIEW_IS_ENABLED,
    EXPERT_ADS_BANNER_MOBILE_VIEW_IS_ENABLED,
  } = getRuntimeConfig()

  const isDesktopEnabled =
    !isMobile && EXPERT_ADS_BANNER_DESKTOP_VIEW_IS_ENABLED

  const isMobileEnabled = isMobile && EXPERT_ADS_BANNER_MOBILE_VIEW_IS_ENABLED

  return Boolean(isDesktopEnabled || isMobileEnabled)
}

export const getReviveConfig = (
  isMobile: boolean,
  /** position ignored for mobile. Return config for position "main" */
  position: IReviveAdsPosition,
  getRuntimeConfig: () => IPublicRuntimeConfig
): IReviveConfig | null => {
  const {
    EXPERT_ADS_BANNER_DESKTOP_VIEW_IS_ENABLED,
    EXPERT_ADS_BANNER_MOBILE_VIEW_IS_ENABLED,
    REVIVE_ID,
    REVIVE_CENTER_DESKTOP_ZONE_ID,
    REVIVE_CENTER_MOBILE_ZONE_ID,
    REVIVE_LEFT_DESKTOP_ZONE_ID,
  } = getRuntimeConfig()

  if (
    (isMobile && !EXPERT_ADS_BANNER_MOBILE_VIEW_IS_ENABLED) ||
    (!isMobile && !EXPERT_ADS_BANNER_DESKTOP_VIEW_IS_ENABLED)
  ) {
    return null
  }
  let zoneId: string

  if (
    !REVIVE_ID ||
    !REVIVE_CENTER_DESKTOP_ZONE_ID ||
    !REVIVE_CENTER_MOBILE_ZONE_ID ||
    !REVIVE_LEFT_DESKTOP_ZONE_ID
  ) {
    console.warn(
      `Revive isn't configured. reviveid: ${REVIVE_ID}, reviveCenterDesktopZoneId: ${REVIVE_CENTER_DESKTOP_ZONE_ID}, reviveCenterMobileZoneId: ${REVIVE_CENTER_MOBILE_ZONE_ID}, reviveLeftDesktopZoneId: ${REVIVE_LEFT_DESKTOP_ZONE_ID}`
    )

    return null
  }

  if (isMobile) {
    zoneId = REVIVE_CENTER_MOBILE_ZONE_ID
  } else {
    zoneId =
      position === "main"
        ? REVIVE_CENTER_DESKTOP_ZONE_ID
        : REVIVE_LEFT_DESKTOP_ZONE_ID
  }

  return {
    reviveId: REVIVE_ID,
    zoneId,
  }
}
