import React from "react"
import { Box } from "../_common"
import commonStyles from "@styles/common.module.scss"

interface IContentSection {
  spacingSize: "small" | "medium" | "big"
}

const ContentSection: React.FC<
  IContentSection & { children: React.ReactNode }
> = ({ spacingSize, children }) => {
  return (
    <Box className={commonStyles[`margin-bottom--${spacingSize}`]}>
      {children}
    </Box>
  )
}

export default ContentSection
