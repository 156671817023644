"use client"

import React from "react"

import { Button } from "../_common"
import classes from "./PrintButton.module.scss"

const PrintButton = () => (
  <Button
    variant="contained"
    size="small"
    color="primary"
    onClick={() => window.print()}
    className={classes.root}
  >
    PRINT
  </Button>
)

export default PrintButton
