"use client"

import React from "react"
import ContentSection from "../../ui-lib/ContentSection"

import { IExpertAdsBanner } from "../../graphql/contentful-schema"
import { useMediaQuery, useTheme } from "../../ui-lib/_common"
import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import ExpertAds from "../../ui-lib/Experts/ExpertAds"
import { isAdsEnabled } from "../../utils/revive"

const ExpertAdsComponent: React.FC<{
  data: IExpertAdsBanner
}> = ({ data: { headline } }) => {
  const { getRuntimeConfig } = useRuntimeConfig()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const isEnabled = isAdsEnabled(isMobile, getRuntimeConfig)

  return isEnabled ? (
    <ContentSection spacingSize="big">
      <ExpertAds headline={headline} position="main" />
    </ContentSection>
  ) : null
}

export default ExpertAdsComponent
