"use client"

import React, { useEffect } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import clsx from "clsx"
import { useTranslations } from "next-intl"

import useScript from "../../../hooks/useScript"
import { Typography, useTheme, useMediaQuery } from "../../_common"
import ExpertAdPlaceholder from "./ExpertAdPlaceholder"
import { getReviveConfig } from "../../../utils/revive"
import { IReviveAdsPosition } from "../../../types"
import { useRuntimeConfig } from "../../../hooks/runtimeConfig"
import classes from "./ExpertAds.module.scss"

interface IExpertAdsProps {
  headline: string | null | undefined
  position: IReviveAdsPosition
  className?: string
}

/**
 * delay is needed to make sure correct layout is rendered
 * so revive script can find expected dom nodes.
 * We have a case when we need to understand whether we render FiltersModule or ads.
 * This involves portals and can be done only on client side after some delay.
 *
 * @see useEffect in src/components/Layout/SidebarAd.tsx
 */
export const DELAY = 500

const ExpertAds: React.FC<IExpertAdsProps> = ({
  headline,
  position,
  className,
}) => {
  const { getRuntimeConfig } = useRuntimeConfig()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const translate = useTranslations("ads")

  const reviveConfig = getReviveConfig(isMobile, position, getRuntimeConfig)

  const [clientLoaded, clientLoadError] = useScript({
    src: reviveConfig
      ? "https://banner.questico.com/www/delivery/asyncjs.php"
      : null,
    withCache: false,
    scriptId: reviveConfig?.reviveId,
    delay: DELAY,
  })

  const cleanReviveObj = (reviveId: string) => {
    if (window.reviveAsync && window.reviveAsync[reviveId]) {
      delete window.reviveAsync[reviveId]
    }
  }

  const searchParamsString = searchParams.toString()

  // we want to clean revive object when user navigates to another page
  // so revive can reinitialize itself for the same revvieId
  useEffect(() => {
    if (reviveConfig?.reviveId) cleanReviveObj(reviveConfig?.reviveId)
  }, [reviveConfig?.reviveId, searchParamsString, pathname])

  if (!reviveConfig || clientLoadError) {
    return null
  }

  if (reviveConfig === null) return null

  const columnCount = position === "main" ? 4 : 1
  const items = new Array(columnCount).fill(1)

  const rootClassName = clsx(classes.ads, className, {
    [classes.adsSidebar]: position === "sidebar",
  })

  return (
    <>
      <div className={rootClassName}>
        {headline && (
          <Typography component="h2" variant="h2" className={classes.headline}>
            {headline}
          </Typography>
        )}
        <div className={classes.scroll}>
          <ul className={classes.list} data-testid="expert-ads">
            {items.map((_, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classes.listItem}
              >
                <ins
                  className={classes.reviveAdContainer}
                  data-revive-id={reviveConfig.reviveId}
                  data-revive-zoneid={reviveConfig.zoneId}
                >
                  {!clientLoaded ? <ExpertAdPlaceholder /> : null}
                </ins>
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.label}>{translate("disclosure")}</div>
      </div>
    </>
  )
}

export default ExpertAds
