"use client"

import React, { useEffect, useState } from "react"
import { useTranslations } from "next-intl"

import ExpertAds, { DELAY } from "../../ui-lib/Experts/ExpertAds"
import classes from "./SidebarAd.module.scss"

const SidebarAd: React.FC = () => {
  const [onlySidebar, setOnlySidebar] = useState(false)
  const translate = useTranslations("ads")

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      const filter = window.document.getElementById("filtersPortal")

      setOnlySidebar(!filter || !filter.hasChildNodes())
    }, DELAY)

    return () => {
      window.clearTimeout(timeout)
    }
  }, [])

  // SidebarAd renders only if there is nothing, but side navigation
  return onlySidebar ? (
    <ExpertAds
      headline={translate("sidebar.title")}
      position="sidebar"
      className={classes.expertAds}
    />
  ) : null
}

export default SidebarAd
