"use client"

import React from "react"
import clsx from "clsx"

import {
  AdviqoLink,
  Typography,
  useMediaQuery,
  useTheme,
  NextImage,
} from "../../../_common"
import { ContentItemProps } from "../../types"
import { buildLinkUrl } from "../../../../utils/slugUtils"
import { ItemViewOptions, TextAlign } from "../../../../types"
import ArrowIcon from "../../../_common/QuesticoIcon/ArrowIcon"
import { makeAttributesObject } from "../../utils"
import classes from "./Stack.module.scss"
import commonStyles from "@styles/common.module.scss"

const Stack: React.FC<ContentItemProps> = ({
  headline,
  subheadline,
  textAlign = TextAlign.LEFT,
  classNames,
  link,
  image: imageMobile,
  imageDesktop,
  itemViewOptions,
  dataAttributes,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const image = !isMobile && imageDesktop ? imageDesktop : imageMobile
  const isStack = itemViewOptions === ItemViewOptions.STACK
  const imageWrapperClass = isStack
    ? classes.imageWrapper
    : classes.imageArticleWrapper

  const headlineStyles = clsx(
    classes.headline,
    commonStyles[`text-align--${textAlign}`]
  )

  const subheadlineStyles = clsx(
    classes.subheadline,
    commonStyles[`text-align--${textAlign}`]
  )

  const rootClasses = clsx(
    classes.root,
    classNames,
    itemViewOptions === ItemViewOptions.STACK
      ? classes.rootStack
      : classes.rootArticle
  )

  const content = (
    <>
      <div className={imageWrapperClass}>
        {isStack ? (
          <NextImage
            className={classes.imageResponsive}
            src={image.url}
            fill
            alt={headline || "stack image"}
          />
        ) : (
          <NextImage
            className={classes.imageRegular}
            src={image.url}
            height={image.height}
            width={image.width}
            alt={headline || "stack image"}
          />
        )}
      </div>
      {headline && (
        <Typography variant="h4" className={headlineStyles}>
          {headline}
        </Typography>
      )}
      {subheadline && (
        <Typography className={subheadlineStyles}>{subheadline}</Typography>
      )}
    </>
  )

  return (
    <div className={rootClasses} {...makeAttributesObject(dataAttributes)}>
      {link ? (
        <AdviqoLink to={buildLinkUrl(link)} className={classes.link}>
          {content}

          {itemViewOptions === ItemViewOptions.STACK && (
            <span className={classes.arrow}>
              <ArrowIcon width={18} height={14} />
            </span>
          )}
        </AdviqoLink>
      ) : (
        content
      )}
    </div>
  )
}

export default Stack
