import React, { ComponentProps } from "react"

import { Box } from "../../_common"
import { ItemViewOptions } from "../../../types"
import Collage from "./views/Collage"
import Stack from "./views/Stack"

type IContentItemProps = ComponentProps<typeof Collage> &
  ComponentProps<typeof Stack>

const ContentItem: React.FC<IContentItemProps> = (props) => {
  const { itemViewOptions } = props

  switch (itemViewOptions) {
    case ItemViewOptions.COLLAGE:
    case ItemViewOptions.COLLAGE_ALTERNATE:
      return <Collage {...props} />
    case ItemViewOptions.STACK:
    case ItemViewOptions.IN_ARTICLE:
      return <Stack {...props} />
    default:
      return <Box>View option {itemViewOptions} is not supported yet</Box>
  }
}

export default ContentItem
