"use client"

import React from "react"
import clsx from "clsx"

import {
  Box,
  Grid,
  AdviqoLink,
  Typography,
  NextImage,
  useMediaQuery,
  useTheme,
} from "../../../_common"
import { ContentItemProps } from "../../types"
import { buildLinkUrl } from "../../../../utils/slugUtils"
import { useViewport } from "../../../../hooks/viewport"
import { ItemViewOptions, TextAlign } from "../../../../types"
import { makeAttributesObject } from "../../utils"
import classes from "./GridCollage.module.scss"
import commonStyles from "@styles/common.module.scss"

const imageCollageSizes = {
  sm: {
    width: 700,
    height: 125,
  },
  md: {
    width: 282,
    height: 305,
  },
}

const imageAboutContainerSizes = {
  sm: {
    width: 116,
    height: 104,
  },
  md: {
    width: 302,
    height: 147,
  },
}

const getImageAboutSizes = (isMobile: boolean) =>
  isMobile
    ? {
        width: 90,
        height: 54,
      }
    : {
        ...imageAboutContainerSizes.md,
        width: imageAboutContainerSizes.md.width - 60,
      }

interface ICommonCollageProps {
  imageUrl: string
  headline: string
  subheadline?: string
  textAlign?: TextAlign | null
}

const ContentCollageAboutSection: React.FC<ICommonCollageProps> = ({
  headline,
  imageUrl,
  subheadline,
  textAlign = "left",
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      <Box
        className={clsx(
          classes.imageWrapper,
          classes.imageWrapperCollageAboutSection
        )}
      >
        <div className={classes.containerImgAboutSection}>
          {/**
           * Note: we need to set `width` and `height` in `NextImage`
           */}
          <NextImage
            src={imageUrl}
            alt={headline}
            width={getImageAboutSizes(isMobile).width}
            height={getImageAboutSizes(isMobile).height}
            className={classes.image}
          />
        </div>
        <Box className={classes.infoCollageAboutSection}>
          <Typography
            component="h3"
            variant="h3"
            className={clsx(
              classes.itemHeadline,
              classes.itemHeadlineCollageAboutSection,
              commonStyles[`text-align--${textAlign}`]
            )}
          >
            {headline}
          </Typography>
          {subheadline && (
            <Box
              className={clsx(
                classes.subHeadline,
                classes.subHeadlineCollageAboutSection
              )}
            >
              {subheadline}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

const ContentCollage: React.FC<ICommonCollageProps> = ({
  imageUrl,
  headline,
  subheadline,
  textAlign = "left",
}) => {
  const { isViewportDown } = useViewport()
  const isMobile = isViewportDown("sm")
  const { sm: smViewSize, md: mdViewSize } = imageCollageSizes

  return (
    <Box className={clsx(classes.imageWrapper, classes.imageWrapperCollage)}>
      <NextImage
        width={isMobile ? smViewSize.width : mdViewSize.width}
        height={isMobile ? smViewSize.height : mdViewSize.height}
        src={imageUrl}
        alt={headline}
        className={clsx(classes.image, classes.imageCollage)}
      />
      <Box className={classes.infoWrapperCollage}>
        <Box className={classes.info}>
          <Typography
            component="h3"
            variant="h3"
            className={clsx(
              classes.itemHeadline,
              classes.itemHeadlineCollage,
              commonStyles[`text-align--${textAlign}`]
            )}
          >
            {headline}
          </Typography>
          {subheadline && (
            <Box
              className={clsx(classes.subHeadline, classes.subHeadlineCollage)}
            >
              {subheadline}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const Collage: React.FC<ContentItemProps> = ({
  headline,
  link,
  image: imageMobile,
  imageDesktop,
  itemViewOptions,
  subheadline,
  textAlign,
  dataAttributes,
}) => {
  const { isViewportDown } = useViewport()
  const isMobile = isViewportDown("sm")

  const image = isMobile ? imageMobile : imageDesktop || imageMobile
  const collageProps: ICommonCollageProps = {
    headline,
    subheadline,
    imageUrl: image && image.url,
    textAlign,
  }

  const content =
    itemViewOptions === ItemViewOptions.COLLAGE ? (
      <ContentCollage {...collageProps} />
    ) : (
      <ContentCollageAboutSection {...collageProps} />
    )

  return (
    <Grid
      item
      xs={12}
      md={6}
      className={classes.collage}
      {...makeAttributesObject(dataAttributes)}
    >
      {link ? (
        <AdviqoLink className={classes.wrapperLink} to={buildLinkUrl(link)}>
          {content}
        </AdviqoLink>
      ) : (
        content
      )}
    </Grid>
  )
}

export default Collage
