"use client"

import React, { useEffect, useRef } from "react"
import { useDomRefs } from "../../hooks/domRefs"
import { TComponentsReferences } from "../../types"
import classes from "./PortalTarget.module.scss"

interface IPortalTargetProps {
  name: TComponentsReferences
}

const PortalTarget: React.FC<IPortalTargetProps> = ({ name }) => {
  const portalRef = useRef<HTMLDivElement>(null)

  const { addRef, delRef, refsMap, withFiltersPortal } = useDomRefs()

  if (name === "filtersPortal") {
    // This is needed to make sure that FiltersModule is aware as soon as possible that filters portal will be rendered, in order to fix jump issue (see ticket https://adviqo.atlassian.net/browse/MISSION-2610)
    withFiltersPortal.current = true
  }

  useEffect(() => {
    if (!refsMap[name]?.current) {
      addRef(name, portalRef)
    }

    return () => {
      delRef(name)

      if (name === "filtersPortal") withFiltersPortal.current = false
    }
  }, [addRef, delRef, name])

  return <div className={classes.portal} id={name} ref={portalRef} />
}

export default PortalTarget
