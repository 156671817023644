import React from "react"
import { Box, Skeleton, useMediaQuery, useTheme } from "../../_common"
import classes from "./ExpertAdPlaceholder.module.scss"

const ExpertAdPlaceholder: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.avatarWrapper}>
        <Skeleton className={classes.avatarSkeleton} variant="rectangular" />
      </Box>
      <Box className={classes.detailsCol}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        {isMobile && <Skeleton variant="text" />}
      </Box>
    </Box>
  )
}

export default ExpertAdPlaceholder
